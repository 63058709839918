import { pickBy, isNumber, includes } from 'lodash';
import { isUsed } from 'site-modules/shared/utils/publication-states';
import { ALLOWED_SEO_SRP_REQUEST_PARAMS } from 'site-modules/shared/constants/allowed-seo-srp-request-params';
import { objectToQueryString } from 'site-modules/shared/utils/string';
import { replaceToSeoKeys } from 'client/site-modules/shared/utils/inventory/params-conversion';

import { makeNiceName } from './nice-name';

export const SRP_BASE_PATHNAME = '/inventory/srp.html';
export const BUY_ONLINE_SRP_PATHNAME = '/car-buying-online/search-cars';

/**
 * Detects whether a key-value pair should be included in SRP link
 * @param value
 * @param key
 * @returns {boolean}
 */
function shouldParamBeIncluded(value, key) {
  return !!(includes(ALLOWED_SEO_SRP_REQUEST_PARAMS, key) && (value || isNumber(value)));
}

/**
 * Generates an escaped SRP link
 * @param {Object} params
 * @param {string} pathName
 * @returns {string}
 */
export const srpLinkBuilder = (params = {}, pathName = SRP_BASE_PATHNAME) => {
  if ((params.isSrpOnUsedCore || params.isReviewSubpage) && params.makeSlug && params.modelSlug && params.year) {
    return `/${params.makeSlug}/${params.modelSlug}/${params.year}/`;
  }
  const cleanParams = pickBy(params, shouldParamBeIncluded);
  const queryParams = objectToQueryString(cleanParams);

  return `${pathName}${queryParams.length ? '?' : ''}${queryParams}`;
};

/**
 * Generates an escaped SRP link
 * @param {Object} params
 * @param {boolean} buyonline
 * @returns {string}
 */
export const srpLinkBuilderWithParamsConversion = (params = {}, buyonline = false) => {
  const pathName = buyonline ? BUY_ONLINE_SRP_PATHNAME : SRP_BASE_PATHNAME;
  const replacedParams = replaceToSeoKeys(params);
  const cleanParams = pickBy(replacedParams, shouldParamBeIncluded);
  const queryParams = objectToQueryString(cleanParams);

  return `${pathName}${queryParams.length ? '?' : ''}${queryParams}`;
};

/**
  Generates 'flat' SRP URL (currently available for used SRP only)
  based on provided vehicle and location.
  Mandatory parameters:
    vehicle {
      make,
      model,
      year,
    },
    visitorLocation {
      city,
      stateCode
    }
*/
export function generateFlatUrl(vehicle, visitorLocation, params = {}) {
  const { make, model, year, trim } = vehicle;
  const { city, stateCode } = visitorLocation;
  const { mileage, radius } = params;
  const queryParams = objectToQueryString({ trim, mileage, radius });
  return `/used-${year}-${makeNiceName(make)}-${makeNiceName(model)}-${makeNiceName(city)}-${makeNiceName(stateCode)}/${
    queryParams.length ? '?' : ''
  }${queryParams}`;
}

/**
 * Generates srp link for used only
 * @param {String} make - Vehicle make
 * @param {String} model - Vehicle model
 * @param {String} type - Vehicle inventoryType only (Used or Used Certified Pre-Owned)
 * @return {String} srp link for used vehicle
 */
export const generateSrpLinkMakeModel = (make, model, type = 'used') =>
  `/${makeNiceName(type)}-${makeNiceName(make)}-${makeNiceName(model)}/`;

/**
 * Generates srp link for new make and model
 * @param {String} make - Vehicle make
 * @param {String} model - Vehicle model
 * @return {String} srp link for new vehicle
 */
export const generateSrpLinkNewMakeModel = (make, model) =>
  `/new-${makeNiceName(make)}-${makeNiceName(model)}-for-sale/`;

/**
 * Generates srp link for lease only
 * @param {String} make - Vehicle make
 * @param {String} model - Vehicle model
 * @return {String} lease srp link
 */
export const generateLeaseSrpLinkMakeModel = (make, model) =>
  `/${makeNiceName(make)}-${makeNiceName(model)}-lease-deals/`;

/**
 * Generates srp link for used make only
 * @param {String} make - Vehicle make
 * @param {String} type - Vehicle inventoryType only (Used or Used Certified Pre-Owned)
 * @return {String} srp link for used vehicle
 */
export const generateSrpLinkUsedMake = (make, type = 'used') => `/${makeNiceName(type)}-${makeNiceName(make)}/`;

/**
 * Generates srp link for new make only
 * @param {String} make - Vehicle make
 * @return {String} srp link for new vehicle
 */
export const generateSrpLinkNewMake = make => `/new-${makeNiceName(make)}-for-sale/`;

/**
 * Generates srp link for lease, make only
 * @param {String} make - Vehicle make
 * @return {String} lease srp link
 */
export const generateLeaseSrpLinkMake = make =>
  `${SRP_BASE_PATHNAME}?inventorytype=new&make=${makeNiceName(make)}&paymenttype=lease`;

/**
 * Generates srp link for lease only
 * @param {String} type - vehicleCategory
 * @return {String} lease srp link
 */
export const generateLeaseSrpLinkType = type => `/best-${makeNiceName(type)}-lease-deals/`;

/**
 * Generates srp link for used type only
 * @param {String} type - vehicleCategory
 * @param {String} inventoryType - Vehicle inventoryType only (Used or Used Certified Pre-Owned)
 * @return {String} srp link for used vehicle
 */
export const generateSrpLinkUsedType = (type, inventoryType = 'used') =>
  `/${makeNiceName(inventoryType)}-${makeNiceName(type)}/`;

/**
 * Generates srp link for new type only
 * @param {String} type - vehicleCategory
 * @return {String} srp link for new vehicle
 */
export const generateSrpLinkNewType = type => `/new-${makeNiceName(type)}-for-sale/`;

export const getNewCarsForSaleSrp = () => '/new-cars-for-sale/';

/**
 * Generates srp link for CPO make model year
 * @param {String} make - Vehicle make
 * @param {String} model - Vehicle model
 * @param {String} year - Vehicle year
 * @return {String} srp link for used vehicle
 */
export const generateSrpLinkCPOMakeModelYear = (make, model, year) =>
  `/used-certified-pre-owned-${makeNiceName(make)}-${makeNiceName(model)}/?year=${year}`;

/**
 * Generates flat srp link for NEW vehicle
 * @param {String} make - Vehicle make
 * @param {String} model - Vehicle model
 * @param {String} type - Vehicle type
 * @return {String} flaet srp link for NEW vehicle
 */
export function generateNewFlatUrl({ make, model, type } = {}) {
  if (make && model) return generateSrpLinkNewMakeModel(make, model);
  if (make) return generateSrpLinkNewMake(make);
  if (type) return generateSrpLinkNewType(type);
  return getNewCarsForSaleSrp();
}

/**
 * Mapping vehicle publication state to SRP inventorytype.
 * For used publicationState we should have 'used,cpo', for new publicationState - 'new'.
 * @type {{NEW: string, USED: string}}
 */
export const INVENTORY_PUB_STATES = {
  NEW: 'new',
  USED: 'used,cpo',
};

export function buildSrpLink({ makeModelSubmodelYear, params, visitorLocation }) {
  const builderParams = {
    make: makeModelSubmodelYear.make.slug,
    model: makeModelSubmodelYear.model.slug,
    year: makeModelSubmodelYear.year,
  };

  if (params.submodel) {
    builderParams.sub = makeModelSubmodelYear.submodels.slug;
  }

  if (params.radius) {
    // not used for the flatUrl, where it gets passed in as an additional argument
    builderParams.radius = params.radius;
  }

  return isUsed(makeModelSubmodelYear.pubStates) && visitorLocation
    ? generateFlatUrl(builderParams, visitorLocation, params.radius && { radius: params.radius })
    : srpLinkBuilder(builderParams);
}
