import { get, cloneDeep, includes } from 'lodash';
import { HTTP_ERROR_500 } from 'client/utils/http-status';
import { buildStringValue } from 'client/site-modules/shared/utils/string';
import { INVENTORY_TYPES } from 'client/constants/inventory-types';
import {
  INVENTORY_TYPE as API_INVENTORY_TYPE,
  PAYMENT_TYPE,
  LEASE_PAYMENT,
  LOAN_PAYMENT,
  MODEL,
  RADIUS,
} from 'site-modules/shared/constants/allowed-inventory-request-params';
import { PUB_STATES_LOWERCASE } from 'client/constants/pub-states';
import { PHOTO_TYPE } from 'client/engagement-handlers/inventory-engagement-handler/constants';
import { isUsed } from 'site-modules/shared/utils/inventory-utils/is-used';
import { isNew } from 'site-modules/shared/utils/inventory-utils/is-new';
import { getRadiusByDmaRank } from 'client/data/models/inventory';
import { VisitorModel } from 'client/data/models/visitor';
import {
  RADIUS_FACET,
  RADIUS_FACET_EXTENDED,
  NATIONWIDE_VALUE,
} from 'site-modules/shared/constants/inventory/static-facets';
import { getPublicationStateByType } from 'site-modules/shared/utils/publication-states';
import {
  isUsedCheapCarsSRP,
  isCheapLeaseSRP,
  isNationalSRP,
  isCarFinderSRP,
} from 'site-modules/shared/utils/inventory/srp-type-checkers';
import { getBrowserQuery } from 'site-modules/shared/utils/inventory/params-conversion';

export const CREATIVE_ID = 'edm-entry-main-container';

export function get500Error(e) {
  const ex = new Error(`Inventory SRP was not loaded - ${e}`);
  ex.status = HTTP_ERROR_500;

  return ex;
}

/**
 * Returns urlPattern if exists or empty string otherwise
 * @param {Object} urlContext
 * @returns {String} urlPattern
 */
export function getUrlPattern(urlContext) {
  return get(urlContext, 'urlPattern', '');
}

/**
 * Returns a selected facet item, or first one if nothing was found
 * @param {Object} facet
 * @param {String} value
 * @returns {Object}
 */
export const getSelectedFacetItem = (facet, value) =>
  facet.values.find(facetItem => facetItem.value === value) || facet.values[0];

/**
 * Returns radius if exists in facets, or first static value otherwise
 * @param {Number} dmaRank
 * @param {String} radius
 * @param {String} [radiusByDMA]
 * @returns {Object}
 */
export const getRadiusValue = (dmaRank, radius, radiusByDMA) =>
  getSelectedFacetItem(RADIUS_FACET_EXTENDED, radius || radiusByDMA || getRadiusByDmaRank(dmaRank).toString()).value;

/**
 * Returns radius from inventory attributes if exists, or one from selected facets, or based on dmaRank otherwise
 * @param {Object} inventoryData
 * @param {Object} visitorLocation
 * @param {Object} [selectedFacets]
 * @returns {Object}
 */
export const getAppliedRadius = ({ inventoryData, visitorLocation, selectedFacets }) => {
  const dmaRank = get(visitorLocation, 'dmaRank', 0);
  const selectedRadius = get(selectedFacets, 'radius[0]');
  const attrRadius = get(inventoryData, 'attributes.radius', '').toString();
  return parseInt(getRadiusValue(dmaRank, attrRadius, selectedRadius), 10);
};

/**
 * Marks previously detected radius as default value if applicable
 * @param {Object} facet
 * @param {String} value
 * @returns {Object}
 */
export const getStaticFacet = (facet, value) => {
  const facetCopy = cloneDeep(facet);
  const selectedFacetItem = getSelectedFacetItem(facetCopy, value);

  selectedFacetItem.selected = true;

  return facetCopy;
};

/**
 * Get page publicationState
 * @param inventoryTypes ex. ['new', 'used', 'cpo']
 * @returns {string} ex. 'new', 'new_used', 'used'
 */
export function getPagePublicationState(inventoryTypes = []) {
  const trimmedInventoryTypes = inventoryTypes.map(type => type.trim());

  let publicationState = PUB_STATES_LOWERCASE.NEW_USED;
  if (trimmedInventoryTypes.length === 1) {
    publicationState = getPublicationStateByType(trimmedInventoryTypes[0]);
  }
  if (
    trimmedInventoryTypes.length === 2 &&
    includes(trimmedInventoryTypes, INVENTORY_TYPES.CPO.toLowerCase()) &&
    includes(trimmedInventoryTypes, PUB_STATES_LOWERCASE.USED)
  ) {
    publicationState = PUB_STATES_LOWERCASE.USED;
  }
  return publicationState;
}

/**
 * Get values for selected items on page.
 * @param {Object} modelState
 * @param {Object} pageFilters
 */
export function getPageFiltersValues(modelState, pageFilters) {
  const selectedMake = get(pageFilters, 'make[0]', null);
  const selectedModel = get(pageFilters, 'model[0]', null);
  const radius = getRadiusValue(modelState.get('location.dmaRank', VisitorModel), get(pageFilters, 'radius[0]'));
  const inventoryType = get(pageFilters, 'inventoryType', []);

  return {
    selectedMake,
    selectedModel,
    radius,
    inventoryType,
  };
}

/**
 * Gets link widget title.
 * @param {boolean} [isLease]
 * @param {object} [urlContext]
 * @param {object} [makeModelSubmodelYear]
 * @returns {string}
 */
export function getLinkWidgetTitle({ isLease, urlContext, makeModelSubmodelYear }) {
  const urlPattern = getUrlPattern(urlContext);
  const make = get(urlContext, 'vehicle.make.name', '') || get(makeModelSubmodelYear, 'make.name', '');
  const model = get(urlContext, 'vehicle.model.name', '') || get(makeModelSubmodelYear, 'model.name', '');

  if (isCheapLeaseSRP(urlPattern)) return 'Cheapest Lease Deals';

  if (isLease) {
    const { inventory } = urlContext;
    const type = inventory && inventory.type;
    const vehicleTitle = buildStringValue(make, model, type);
    return vehicleTitle ? `Related ${vehicleTitle} Lease Deals` : 'Related Lease Deals';
  }

  if (isUsedCheapCarsSRP(urlPattern)) return 'Cheap Cars For Sale';

  if (make && model) return `Related ${make} ${model} info`;

  if (make) return `Related ${make} info`;

  return 'Related vehicle information';
}

/**
 * Determines that selected inventory type is USED type.
 * @param {object} facets
 * @returns {boolean}
 */
export function isUsedInventoryTypeSelected(facets) {
  const inventoryType = facets && facets[API_INVENTORY_TYPE];
  return !!inventoryType && !!inventoryType.length && !inventoryType.some(isNew);
}

/**
 * Determines that selected inventory type is NEW type.
 * @param {object} facets
 * @returns {boolean}
 */
export function isNewInventoryTypeSelected(facets) {
  const inventoryType = facets && facets[API_INVENTORY_TYPE];
  return !!inventoryType && !!inventoryType.length && !inventoryType.some(isUsed);
}

/**
 * Gets data for inventory list tracking.
 * @param {number} radius
 * @param {object} inventoryData
 * @param {object} selectedFacets
 * @param {object} urlContext
 * @param {boolean} isSrp
 * @returns {object}
 */
export function getInventoryListTrackingData({ radius, inventoryData, selectedFacets, urlContext, isSrp }) {
  const trackingData = {
    inventory: get(inventoryData, 'inventories.results', []),
    totalCount: get(inventoryData, 'inventories.totalNumber'),
    attributes: get(inventoryData, 'attributes', {}),
    creativeId: CREATIVE_ID,
    defaults: { defaultNoImageType: PHOTO_TYPE.NO_IMAGE },
    isSrp,
    isNational: isNationalSRP(urlContext) || isCarFinderSRP(selectedFacets),
    radius,
  };

  return trackingData;
}

export function getPageName(flatUrlPath) {
  return flatUrlPath ? `srp-${flatUrlPath}` : null;
}

export function isFilteredByPaymentType(selectedFacets) {
  return (
    selectedFacets &&
    (!!selectedFacets[PAYMENT_TYPE] || !!selectedFacets[LEASE_PAYMENT] || !!selectedFacets[LOAN_PAYMENT])
  );
}

export function getSrpBrowserQuery(selectedFacets) {
  // If no model then replace nationwide with 500 radius
  const facets =
    !get(selectedFacets, `${MODEL}[0]`) && get(selectedFacets, `${RADIUS}[0]`) === NATIONWIDE_VALUE
      ? { ...selectedFacets, radius: [RADIUS_FACET.values[RADIUS_FACET.values.length - 1].value] }
      : selectedFacets;

  return getBrowserQuery(facets);
}

export function isNationwide(radius) {
  return radius && radius.toString() === NATIONWIDE_VALUE;
}
