import { get, isEmpty, omit, reduce } from 'lodash';
import { NativeAdsCreativeConfigModel } from 'client/data/models/native-ads-creative-config';
import { handlePublicationState } from 'site-modules/shared/utils/publication-states';
import { getMediaImageUrl } from 'client/utils/image-helpers';
import { EAS_LISTING_AD, SITE_SERVED_ADS } from 'site-modules/shared/components/native-ad/utils/constants';

export function transformCreativeConfig(data) {
  const config = get(data, '[0]', {});
  return omit({ ...config, ...config.configRef }, ['configRef']);
}

export function getEmptyCreativeConfig(adName) {
  return { adName, siteServedAdsUpdater: Date.now() };
}

export function isEmptyCreativeConfig(creativeConfig) {
  return isEmpty(omit(creativeConfig, ['adName', 'siteServedAdsUpdater']));
}

export function getEmptyCreativeConfigResult(adName) {
  return [getEmptyCreativeConfig(adName)];
}

export function definePlatform(isSmall) {
  return isSmall ? 'mobile' : 'desktop';
}

export function addZipCode(text, zipCode) {
  if (typeof text !== 'string') {
    return text;
  }

  const PATTERN = '%%PATTERN:zip%%';

  return text.replace(PATTERN, zipCode);
}

export function replacePattern(data, pattern, value) {
  if (typeof data !== 'string') {
    return data;
  }

  return data.replace(pattern, value);
}

export function getAdImageUrl(path) {
  const absoluteUrlRegExp = /^https?:\/\/|^\/\//i;
  return absoluteUrlRegExp.test(path) ? path : getMediaImageUrl(path);
}

function getVehicleValue(vehicle, params, defaultValue = 'none') {
  for (let i = 0; i < params.length; i += 1) {
    const value = get(vehicle, params[i]);
    // remove empty space or dash (see getNiceName)
    if (value && `${value}`.replace(/[\s-]/g, '')) {
      return value;
    }
  }
  return defaultValue;
}

export function getAdParamsByVehicle(vehicle) {
  const make = getVehicleValue(vehicle, ['make.adTargetId', 'make.niceName', 'makeNiceName']);
  const model = getVehicleValue(vehicle, ['model.adTargetId', 'model.niceName', 'modelNiceName']);
  const subModel = getVehicleValue(vehicle, ['subModel.adTargetId', 'subModel.name', 'subModelName']);
  const type = getVehicleValue(vehicle, ['type.adTargetId', 'type.niceName']);
  const year = getVehicleValue(vehicle, ['modelYear.year', 'year'], 'any');
  const pubState = handlePublicationState(getVehicleValue(vehicle, ['publicationState'], 'new'));
  const bt = get(vehicle, 'bt', 'none');

  return { make, model, subModel, type, year, pubState, bt };
}

/**
 * generate ads path
 * @param mobile - platform
 * @param vehicle - vehicle object is as described in client/actions/vehicle.js
 * @param pageLocation - pageContext.location object
 * @param spaMode - should be true for the page like SRP, it is used to support "One ad impression per page view", see ADS-6262
 * @returns {string}
 */
export function getCreativeConfigAdPathByVehicle(mobile, vehicle, pageLocation, spaMode = false) {
  const platform = mobile ? 'mobile' : 'wired';
  const { make, model, subModel, type, year, pubState, bt } = getAdParamsByVehicle(vehicle);
  const pagePath = spaMode ? (get(pageLocation, 'pathname') || 'default') + get(pageLocation, 'search', '') : 'default';

  return `platform["${platform}"].bt["${bt}"].make["${make}"].model["${model}"].subModel["${subModel}"].type["${type}"].year["${year}"].pubState["${pubState}"].pagePath["${pagePath}"].ads`;
}

export function isValueDefined(value) {
  return !!value && value !== 'none';
}

export function getTrimNamesText(trimList) {
  return trimList.join(', ').replace(/,([^,]*)$/, ' and$1');
}

export function getTrimNamesAndMoreCount(trimList) {
  if (!trimList || !trimList.length) {
    return '';
  }
  let trimsText = getTrimNamesText(trimList, true);
  if (trimsText.length < 60) {
    return trimsText;
  }

  let sliceEnd = trimList.length + 1;
  do {
    sliceEnd -= 1;
    trimsText = getTrimNamesText(trimList.slice(0, sliceEnd), true);
  } while (trimsText.length > 48);

  const trims = trimList.slice(0, sliceEnd).join(', ');
  return `${trims} and ${trimList.length - sliceEnd} more`;
}

const ADZERK_PROPERTY_MAPPING = {
  [EAS_LISTING_AD.AD_NAME]: {
    lineIitemIid: 'cddId',
    siteServedCreativeId: 'ctucId',
  },
};

function getAdZerkPropertyData({
  creativeConfig,
  position,
  visitorId,
  sessionId,
  page,
  legacy,
  creativeId,
  clickCorrelatorTs,
}) {
  const { creativeConfigData, adName } = creativeConfig;
  const { lineItemId, siteServedCreativeId } = creativeConfigData;

  const lineItemIdValue = creativeConfigData[(ADZERK_PROPERTY_MAPPING[adName]?.lineIitemIid)] || lineItemId;
  const siteServedCreativeIdValue =
    creativeConfigData[(ADZERK_PROPERTY_MAPPING[adName]?.siteServedCreativeId)] || siteServedCreativeId;

  const propertiesMap = {
    visitor_id: visitorId,
    session_id: sessionId,
    line_item_id: lineItemIdValue,
    site_served_creative_id: siteServedCreativeIdValue,
    page_name: get(legacy, 'pageName') || get(page, 'name'),
    creative_id: creativeId,
    position,
    click_correlator_ts: clickCorrelatorTs,
  };
  const properties = reduce(propertiesMap, (result, value, key) => `${result}&property:${key}=${value}`, '');
  return properties;
}

export function trackAdZerkClick(params) {
  const {
    creativeConfig: {
      traffickingData: { adzerkClickUrl },
    },
  } = params;

  if (adzerkClickUrl) {
    const customData = getAdZerkPropertyData(params);
    fetch(`${adzerkClickUrl}&noredirect${customData}`);
  }
}

export function trackAdZerkImpression(params) {
  const {
    creativeConfig: {
      traffickingData: { adzerkImpressionUrl },
    },
    clickCorrelatorTs,
  } = params;

  if (clickCorrelatorTs && adzerkImpressionUrl) {
    const customData = getAdZerkPropertyData(params);
    return `${adzerkImpressionUrl}${customData}`;
  }
  return null;
}

function hasCreativeConfig(creativeConfigs, adName) {
  return (
    Array.isArray(creativeConfigs[adName]) && creativeConfigs[adName].some(config => !isEmptyCreativeConfig(config))
  );
}

export function siteServedAdsCriticalCss(creativeConfigs = {}, requiredAds = []) {
  return {
    siteServedAds: requiredAds.length
      ? requiredAds.map(adName => hasCreativeConfig(creativeConfigs, adName)).join()
      : Object.keys(creativeConfigs).some(
          adName => SITE_SERVED_ADS.includes(adName) && hasCreativeConfig(creativeConfigs, adName)
        ),
  };
}

export function unsoldSiteServedAds(creativeConfigs = {}, adNames = []) {
  const hasSiteServedAds = adNames.every(adName => hasCreativeConfig(creativeConfigs, adName));
  return !hasSiteServedAds;
}

export function setAdsListToPreload(preloader, state, adsList = [], vehicle, options = {}) {
  const { pageLocation, spaMode, skipSetList } = options;
  const { mobile, featureFlags } = state;
  const siteServedSeparatedCalls = !!featureFlags?.siteServedSeparatedCalls;

  const path = getCreativeConfigAdPathByVehicle(mobile, vehicle, pageLocation, spaMode);

  if (!skipSetList) {
    preloader.set('pageAdsList', NativeAdsCreativeConfigModel, siteServedSeparatedCalls ? [] : adsList);
  }
  if (siteServedSeparatedCalls) {
    // for verification in e2e
    if (!skipSetList) {
      preloader.set('pageAdsListSeparatedCalls', NativeAdsCreativeConfigModel, adsList);
    }

    Array.from(adsList || []).forEach(adName => {
      preloader.resolve(`${path}["${adName}"]`, NativeAdsCreativeConfigModel);
    });
  } else {
    preloader.resolve(path, NativeAdsCreativeConfigModel);
  }
}
