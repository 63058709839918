/**
 *
 * The list of allowed parameters what used in query parameters on SRP
 *
 * NOTE: keep it consistent with `allowed-inventory-request-params.js`
 * Without consistency, newly added parameters will be ignored by purchase
 * funnel SRP calls
 *
 */
// The 'boostDebug' is used for debugging purposes only
export const BOOST_DEBUG = 'boostDebug';
export const CYLINDERS_COUNT = 'cylinder';
export const DEAL_TYPE = 'dealtype';
export const DELIVERY_TYPE = 'deliverytype';
export const DISPLAY_PRICE = 'price';
export const DRIVE_TRAIN = 'drivetrain';
export const EXTERIOR_COLOR = 'extcolor';
export const FEATURE = 'cgf';
export const FUEL_TYPE = 'fueltype';
export const HOME_DELIVERY = 'homedelivery';
export const HISTORY_INFO = 'historyinfo';
export const EXCLUDE_HISTORY_INFO = '-historyInfo';
export const INTERIOR_COLOR = 'intcolor';
export const INVENTORY_TYPE = 'inventorytype';
export const LEASE_PAYMENT = 'lease';
export const LOAN_PAYMENT = 'loan';
export const MAKE = 'make';
export const MILEAGE = 'mileage';
export const MODEL = 'model';
export const NATIONAL_INVENTORY = 'national';
export const OPTION = 'options';
export const PAGE_NUMBER = 'pagenumber';
export const PAYMENT_TYPE = 'paymenttype';
export const PRICE_DROP = 'priceDrop';
export const WITH_PHOTO = 'withPhoto';
export const WITH_PRICE = 'withPrice';
export const EXCLUDE_IN_TRANSIT = 'excludeInTransit';
export const PRODUCT_FEATURE = 'productfeature';
export const RADIUS = 'radius';
export const RECENTLY_LISTINGS = 'recentlyListings';
// The`dealerlocationid` is used in GW to show inventory for one dealer
export const ROOFTOP_ID = 'dealerlocationid';
export const SESSION_ID = 'sessionid';
export const SORT_BY = 'sort';
export const SUBMODEL_ID = 'submodelid';
export const TRANSMISSION = 'transmission';
export const TRIM = 'trim';
export const VEHICLE_CATEGORY = 'type';
export const YEAR = 'year';
// ---/ This group is only used in FILTER BY sections
export const VIN = 'vin';
export const STOCK_NUMBER = 'stockNumber';
export const FRANCHISE_ID = 'franchiseId';
export const STYLE_ID = 'styleId';
// ---\

export const COMBINE_MPG = 'combinedMPG';
export const ELECTRICITY_RANGE = 'electricityRange';
export const BATTERY_RANGE_SCORE = 'batteryRangeScore';
export const TRUCK_CAB_SIZE = 'truckCabSize';
export const BED_LENGTH = 'bedLength';
export const DISPLACEMENT = 'displacement';
export const REAR_WHEELS = 'rearWheels';
export const ENGINE_TYPE = 'engineType';
export const BODY_TYPE = 'bodyType';
export const EDITORIAL_RATING = 'editorialRating';
export const EDITORIAL_LABEL = 'editorialLabel';

export const COMFORT_CONVENIENCE = 'comfortConvenience';
export const SAFETY = 'safety';
export const ENGINE_EXTERIOR = 'engineExterior';
export const ENTERTAINMENT = 'entertainment';

// Note that 'zip' parameter in URL is not supported by venom platform

// Used by GW Car Buying experience
export const DEALER_IDS = 'dealerIds';

// This is param exists on GW, but is not used widely, and we don't have mapping for it
// Use `submodelid` instead
export const SUBMODEL = 'sub';

export const CREDIT_PROVIDER = 'creditProvider';
export const PREQUAL_MSG = 'prequal_msg'; // note this is not needed for purchase-funnel requests, only FE code, so it is not included in allowed-inventory-request-params.js

export const INITIAL_URL_PATTERN = 'initialUrlPattern';
export const TOTAL_SEATING = 'totalSeating';
export const DELIVERY_FEE = 'deliveryFee';
// NOTE: Alphabetical order
export const ALLOWED_SEO_SRP_REQUEST_PARAMS = [
  BOOST_DEBUG,
  COMFORT_CONVENIENCE,
  CREDIT_PROVIDER,
  CYLINDERS_COUNT,
  DEALER_IDS,
  DEAL_TYPE,
  DELIVERY_TYPE,
  DISPLAY_PRICE,
  DRIVE_TRAIN,
  ENGINE_EXTERIOR,
  ENTERTAINMENT,
  EXTERIOR_COLOR,
  FEATURE,
  FUEL_TYPE,
  HOME_DELIVERY,
  HISTORY_INFO,
  EXCLUDE_HISTORY_INFO,
  INTERIOR_COLOR,
  INVENTORY_TYPE,
  LEASE_PAYMENT,
  LOAN_PAYMENT,
  MAKE,
  MILEAGE,
  MODEL,
  NATIONAL_INVENTORY,
  OPTION,
  PAGE_NUMBER,
  PAYMENT_TYPE,
  PREQUAL_MSG, // note this is not needed for purchase-funnel requests, only FE code, so it is not included in allowed-inventory-request-params.js
  PRICE_DROP,
  PRODUCT_FEATURE,
  RADIUS,
  RECENTLY_LISTINGS,
  ROOFTOP_ID,
  SAFETY,
  SESSION_ID,
  SORT_BY,
  SUBMODEL,
  SUBMODEL_ID,
  TRANSMISSION,
  TRIM,
  VEHICLE_CATEGORY,
  YEAR,
  VIN,
  STOCK_NUMBER,
  FRANCHISE_ID,
  STYLE_ID,
  COMBINE_MPG,
  ELECTRICITY_RANGE,
  TRUCK_CAB_SIZE,
  BED_LENGTH,
  DISPLACEMENT,
  REAR_WHEELS,
  ENGINE_TYPE,
  BODY_TYPE,
  INITIAL_URL_PATTERN,
  WITH_PHOTO,
  WITH_PRICE,
  DELIVERY_FEE, // note this is not needed for purchase-funnel requests, only FE code, so it is not included in allowed-inventory-request-params.js
  EXCLUDE_IN_TRANSIT,
  BATTERY_RANGE_SCORE,
  CREDIT_PROVIDER,
  TOTAL_SEATING,
  EDITORIAL_RATING,
  EDITORIAL_LABEL,
];
