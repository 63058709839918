import { get, set, cloneDeep, flatMap } from 'lodash';
import { CAR_TYPES } from 'client/data/constants/car-types';
import { handlePublicationState } from 'client/site-modules/shared/utils/publication-states';

export const GPT_NETWORK_CODE = '75548861';
const INDEX = 'index';
const DEALERP_SRP_PARAM = 'dealerlocationid';
const REGEX_ZONE_SCRUB = /([\$\-\_\+\!\*\'\(\),\" ])/gi; // eslint-disable-line no-useless-escape
export const DEFAULT_VEHICLE = {
  type: {},
  make: {},
  model: {},
  subModel: {},
  modelYear: {
    year: 'agnostic',
  },
};

const MAKE_PAGE_CATEGORY = ['make_index', 'make_lease_deals'];

function detectTypePage(location) {
  const carTypeSlugs = flatMap(CAR_TYPES, type => type.slug).join('|');
  const pathname = get(location, 'pathname', '');

  return pathname.search(`/${carTypeSlugs}/g`) > -1;
}

function hpUseCase(page, vehicle, zones, app) {
  let result;
  if (page && page.category && page.category === 'home_page') {
    const str = `${app}.homepage`;
    result = zones.concat([str]);
  }
  return result;
}

function insiderDashboardUseCase(page, vehicle, zones, app) {
  let result;
  if (page && page.category && page.category === 'dashboard_page') {
    const str = `${app}.dashboard`;
    result = zones.concat([str]);
  }
  return result;
}

function ddpUseCase(page, vehicle, zones, app, location) {
  let result;
  const isDealerSrp = get(location, `query.${DEALERP_SRP_PARAM}`, null);
  if (page && page.category && page.category === 'car_inventory') {
    const str = `${app}.carinventory`;
    const carInventoryPage = isDealerSrp ? 'dealer' : 'all';
    result = zones.concat([str, carInventoryPage]);
    if (['car_inventory_ddp_map', 'car_inventory_ddp_map_instant_offer'].includes(page.name)) {
      result.push('ddp');
    }
  }
  return result;
}

function typeUseCase(page, vehicle, zones, app) {
  let result;
  if (vehicle && vehicle.type.niceName) {
    const str = `${app}.new.type`;
    result = zones.concat([str, vehicle.type.niceName]);
  }
  return result;
}

function makeUseCase(page, vehicle, zones, app) {
  let result;
  if (vehicle && vehicle.make.niceName && page.category !== 'make_type_index') {
    const str = `${app}.${handlePublicationState(vehicle.publicationState)}.mdl`;
    result = zones.concat([str, vehicle.modelYear.year, vehicle.make.niceName]);
  }
  return result;
}

function makeIndexCase(page, vehicle, zones, app) {
  let result;
  if (page && MAKE_PAGE_CATEGORY.includes(page.category)) {
    const str = `${app}.${handlePublicationState(vehicle.publicationState)}.make`;
    result = zones.concat([str, vehicle.make.niceName, vehicle.modelYear.year]);
  }
  return result;
}

function usedLandingUseCase(page, vehicle, zones, app) {
  let result;
  if (page && page.category === 'used_cars_index') {
    const str = `${app}.usedcarsindex`;
    result = zones.concat(str);
  }
  return result;
}

function newLandingUseCase(page, vehicle, zones, app) {
  let result;
  if (page && page.category === 'new_cars_index') {
    const str = `${app}.newcarsindex`;
    result = zones.concat(str);
  }
  return result;
}

function CpoLandingUseCase(page, vehicle, zones, app) {
  let result;
  if (page && page.category === 'cpo_programs') {
    const str = `${app}.cpoprograms`;
    result = zones.concat(
      page.name === 'cpo_programs_make_comparison' ? [str, 'make', 'comparison'] : [str, 'cars', INDEX]
    );
  }
  return result;
}

function appraisalLandingUseCase(page, vehicle, zones, app) {
  let result;
  if (page && page.category === 'used_cars_tmv_appraiser') {
    const str = `${app}.appraisal`;
    result = zones.concat([str, INDEX]);
  }
  return result;
}

function carReviewsLandingUseCase(page, vehicle, zones, app) {
  let result;
  if (page && page.category === 'car_reviews_index') {
    const str = `${app}.carreviews`;
    result = zones.concat([str, INDEX]);
  }
  return result;
}

function editorialUseCase(page, vehicle, zones, app, location) {
  let result;
  const isTypePage = detectTypePage(location);

  if (page && page.category && (page.category === 'video' || page.category === 'advice')) {
    const str = `${app}.${isTypePage ? 'type' : page.category}`;
    const pageName = page.name.split('_');
    // remove category duplication inside name
    pageName.shift();
    result = zones.concat(isTypePage ? [str, page.category, ...pageName] : [str, ...pageName]);
  }
  return result;
}

function modelUseCase(page, vehicle, zones, app) {
  let result;

  if (vehicle && (vehicle.subModel.niceName || vehicle.model.niceName)) {
    const str = `${app}.${handlePublicationState(vehicle.publicationState)}.mdl`;
    result = zones.concat([str, vehicle.modelYear.year, vehicle.make.niceName]);
  }
  return result;
}

function carNewsUseCase(page, vehicle, zones, app) {
  let result;
  if (page && ['car_news_industry_article_index', 'other_about_us_author_index', 'kia_carnews'].includes(page.name)) {
    const str = `${app}.carnews`;
    const article = 'article';
    if (vehicle.make.niceName) {
      result = zones.concat([str, article, 'make', vehicle.make.niceName, vehicle.modelYear.year]);
    } else if (vehicle.type.niceName) {
      result = zones.concat([str, article, 'type', vehicle.type.niceName]);
    } else if (page.name === 'other_about_us_author_index') {
      result = zones.concat([str, article, 'author']);
    } else {
      result = zones.concat([str, INDEX]);
    }
  }
  return result;
}

function comparatorUseCase(page, vehicle, zones, app) {
  let result;
  let str;

  if (page && page.category && page.category === 'comparator') {
    if (!(vehicle.make.niceName || vehicle.modelYear.niceName)) {
      str = `${app}.comparator`;
      result = zones.concat(str);
    } else {
      str = `${app}.${vehicle.publicationState}.mdl`;
      result = zones.concat([str, vehicle.modelYear.year, vehicle.make.niceName]);
    }
  }

  return result;
}

function photoflipperUseCase(page, vehicle, zones, app) {
  let result;

  if (page && page.category && page.category === 'photoflipper_index') {
    const str = `${app}.${vehicle.publicationState}.mdl`;
    result = zones.concat([str, vehicle.modelYear.year, vehicle.make.niceName]);
  }

  return result;
}

function incentivesUseCase(page, vehicle, zones, app) {
  let result;
  if (page && page.category === 'car_incentives') {
    const str = `${app}.carincentives`;
    result = zones.concat(str);
  }
  return result;
}

function calculatorsUseCase(page, vehicle, zones, app) {
  let result;
  if (page && page.category === 'calculators') {
    const str = `${app}.calculators`;
    result = zones.concat([str, INDEX]);
  }
  return result;
}

function calculatorsAffordabilityUseCase(page, vehicle, zones, app) {
  let result;
  if (page && page.category === 'calculators_affordability') {
    const str = `${app}.calculators`;
    result = zones.concat([str, 'affordability']);
  }
  return result;
}

function calculatorsSimplifiedUseCase(page, vehicle, zones, app) {
  let result;
  if (page && (page.category === 'calculators_loan_payment' || page.category === 'calculators_gas_guzzler')) {
    const str = `${app}.calculators`;
    result = zones.concat([str, 'simplified', 'pricing']);
  }
  return result;
}

function calculatorsIncentivesRebatesUseCase(page, vehicle, zones, app) {
  let result;
  if (page && page.category === 'calculators_incentives_rebates') {
    const str = `${app}.calculators`;
    result = zones.concat([str, 'incentives', 'rebates']);
  }
  return result;
}

function calculatorsLeaseUseCase(page, vehicle, zones, app) {
  let result;
  if (page && page.category === 'calculators_lease') {
    const str = `${app}.calculators`;
    result = zones.concat([str, 'car', 'lease']);
  }
  return result;
}

function calculatorsLeaseVsBuyUseCase(page, vehicle, zones, app) {
  let result;
  if (page && page.category === 'calculators_lease_vs_buy') {
    const str = `${app}.calculators`;
    result = zones.concat([str, 'car', 'lease', 'vs', 'buy']);
  }
  return result;
}

function tmvIndexUseCase(page, vehicle, zones, app) {
  let result;
  if (page && page.category === 'tmv_index') {
    const str = `${app}.tmvindex`;
    result = zones.concat(str);
  }
  return result;
}

function tcoIndexUseCase(page, vehicle, zones, app) {
  let result;
  if (page && page.name === 'other_tco_index') {
    const str = `${app}.other`;
    result = zones.concat([str, 'tco', 'index']);
  }
  return result;
}

function topRatedUseCase(page, vehicle, zones, app) {
  let result;
  if (page && page.name === 'car_reviews_best_cars_index') {
    const str = `${app}.advice`;
    result = zones.concat([str, 'best-cars', 'article']);
  }
  return result;
}

function mostPopularCarsUseCase(page, vehicle, zones, app) {
  let result;
  if (page && page.category === 'best_selling_cars') {
    const str = `${app}.mostpopular`;
    result = zones.concat([str, INDEX]);
  }
  return result;
}

function dealershipsLandingUseCase(page, vehicle, zones, app) {
  let result;
  if (page && page.name === 'localdealers_make_index') {
    const str = `${app}.localdealers`;
    result = zones.concat([str, INDEX]);
  }
  return result;
}

function defaultUseCase(page, vehicle, zones) {
  return zones;
}

export function getVehicle(vehicle) {
  // Set default value (agnostic) for case of multiple or empty values of years
  const extendedVehicle = { ...DEFAULT_VEHICLE, ...cloneDeep(vehicle) };

  // To ensure that year is defined with default value
  // Vehicle object contains empty/null values sometimes, which prevents applying defaults
  // TODO: review `vehicle` object to contain only existing values
  // TODO: reference: client/actions/vehicle.js
  if (!get(extendedVehicle, 'modelYear.year')) {
    set(extendedVehicle, 'modelYear.year', DEFAULT_VEHICLE.modelYear.year);
  }

  return extendedVehicle;
}

/**
 * Use cases at the beginning of the array will take higher precedence.
 */
const useCases = [
  carNewsUseCase,
  comparatorUseCase,
  photoflipperUseCase,
  modelUseCase,
  newLandingUseCase,
  usedLandingUseCase,
  appraisalLandingUseCase,
  carReviewsLandingUseCase,
  editorialUseCase,
  makeIndexCase,
  makeUseCase,
  typeUseCase,
  hpUseCase,
  insiderDashboardUseCase,
  ddpUseCase,
  incentivesUseCase,
  calculatorsUseCase,
  calculatorsAffordabilityUseCase,
  calculatorsSimplifiedUseCase,
  calculatorsIncentivesRebatesUseCase,
  calculatorsLeaseUseCase,
  calculatorsLeaseVsBuyUseCase,
  CpoLandingUseCase,
  tmvIndexUseCase,
  tcoIndexUseCase,
  topRatedUseCase,
  mostPopularCarsUseCase,
  dealershipsLandingUseCase,
  defaultUseCase,
];

/**
 * Generate gptApp name 'mobile' or 'wired'
 *
 * @returns {string}
 */
function getGptApp(mobile, breakpoint) {
  return mobile || ['xs', 'sm'].includes(breakpoint) ? 'mobile' : 'wired';
}

function determineZones(page, vehicle, app, location) {
  const zones = [];
  return useCases.reduce((res, useCase) => res || useCase(page, vehicle, zones, app, location), undefined);
}

/**
 * sitename specific to emdunds for ad from google (gpt)
 * @param {Object} page page level values
 * @param {String} page.name edw page name
 * @param {String} page.category edw page category
 * @param {Object} location url info similar to window.location
 * @param {Object} vehicle page level vehicle object
 * @param {Boolean} mobile specifies if the web site is in mobile state
 * @param {String} responsive breakpoint

 * @returns {string}
 */
export function generateSitename(page, location, vehicle, mobile, breakpoint) {
  const zones = determineZones(page, getVehicle(vehicle), getGptApp(mobile, breakpoint), location);
  return zones
    .join('/')
    .replace(REGEX_ZONE_SCRUB, '')
    .toLowerCase();
}
